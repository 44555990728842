<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="list" v-for="i in Math.ceil(dataList.length/4)" :key="i">
          <div
            class="specialistInfo"
            v-for="(item,j) in dataList.slice((i-1)*4,i*4)"
            :key="j"
            @click="handleDetailClick(item.id)"
          >
            <!--            <img src="../../static/images/head.png"  width="71"/>-->
            <img :src="item.pictureUrl" width="71" />
            <div class="textInfo">
              <div class="specialist_name">{{item.expertName}}</div>
              <div class="specialist_icon">{{expertLevelDic[item.expertLevel]}}</div>
              <div class="specialist_liable">领域：{{fieldDic[item.field]}}</div>
              <div class="specialist_liable">单位：{{item.unitName}}</div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'specialList',
  components: {
    TopFilter
  },
  data() {
    return {
      fieldDic: {
        1: "农林/牧渔/食品", 2: "交通/运输/包装", 3: "化工/冶金/生物", 4: "纺织/造纸", 5: "建筑/采矿/照明", 6: "机械/加工/设备", 7: "服饰/日用/家电", 8: '通信/电子/数码',
        9: '医疗/保健/美容',
        10: '能源/动力/电力',
        11: '法律领域',
      },
      expertLevelDic: { 1: "高级知识产权师" },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      typeList: [
        {
          name: '领域类别',
          code: 'field',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '农林/牧渔/食品',
              val: 0,
            },
            {
              name: '交通/运输/包装',
              val: 1,
            },
            {
              name: '化工/治金/生物',
              val: 2,
            },
            {
              name: '纺织/造纸',
              val: 3,
            },
            {
              name: '建筑/采矿/照明',
              val: 4,
            },
            {
              name: '机械/加工/设备',
              val: 5,
            },
            {
              name: '服饰/日用/家电',
              val: 6,
            },
            {
              name: '通信/电子/数码',
              val: 7,
            },
            {
              name: '医疗/保健/美容',
              val: 8,
            },
            {
              name: '能源/动力/电力',
              val: 9,
            },
            {
              name: '法律领域',
              val: 10,
            },
          ]
        },

      ],
      sortConfig: [
      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleDetailClick(id) {
      this.$router.push({ path: "/intellectualProperty/specialDetail", query: { id: id } });
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.intellectualProperty.expertList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .list {
    display: flex;
    justify-content: space-around;
    margin: 0 20px;
    .specialistInfo {
      cursor: pointer;
      margin: 20px;
      width: 22%;
      border: 0.1px dotted black;
      background: rgba(255, 255, 255, 0.1);
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .textInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        color: black;
        .specialist_name {
          font-size: 16px;
          font-family: "Arial Black";
        }
        .specialist_icon {
          color: blue;
          font-size: 14px;
          background-color: #d6e3e5;
          margin-bottom: 10px;
        }
        .specialist_liable {
          width: 100%;
          color: grey;
          font-size: 14px;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
